<template>
  <div class="list-page">
    <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>
    <list-tabs v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr" @tab-click="handleChangeTab">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <template v-if="filterForm.tab == 'report' || filterForm.tab == 'normal'">
          <el-button type="success" size="small" @click="handlePass(-1, 2)">通过</el-button>
          <el-button type="warning" size="small" @click="openUncheck()">不通过</el-button>
          <el-button type="primary" size="small" @click="blockComment(-1)">拉黑</el-button>
        </template>
        <el-button size="small" v-if="filterForm.tab == 'blacklist'" @click="unDelBlack(-1)">移出黑名单</el-button>
        <el-button size="small" v-if="filterForm.tab == 'deleted'" type="success" @click="handleReduct(-1)">还原
        </el-button>
        <el-button size="small" v-if="filterForm.tab != 'blacklist'" type="danger" @click="handleDel(-1)">删除
        </el-button>
      </div>

      <el-table :data="list" stripe @selection-change="handleSelectionChange" @sort-change="sortChange"
        class="thead-light">
        <el-table-column type="selection"></el-table-column>
        <el-table-column v-for="(th,index) in thead" :key="index" :label="th.label" :min-width="th.minWidth"
          show-overflow-tooltip :sortable="th.sortable">
          <template slot-scope="scope">
            <span v-if="th.prop ==='check_text'">
              <list-status-text :type="scope.row.check_status === 0 ? 'warning' : (scope.row.check_status === 1 ? 'info' : 'success')" :text="scope.row.check_text" />
            </span>
            <!-- 举报数 -->
            <div v-else-if="th.prop === 'complaints_count'">
              {{ scope.row[th.prop] }}
              <el-button v-if="scope.row[th.prop] > 0" type="text" @click="checkReportRecode(scope.row)">查看
              </el-button>
            </div>
            <PopoverInputEdit v-else-if="th.prop ==='praise_count'" style="width: 100px"
              v-model="scope.row.praise_count" input-type="number" :validator="validator"
              @confirm="updateIsShow(scope.row.praise_count, scope.row.id)">
            </PopoverInputEdit>
            <span v-else>{{scope.row[th.prop] | placeholder}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" :width="filterForm.tab === 'deleted' ? 180 : 120">
          <template slot-scope="scope">
            <el-button @click="EditRow(scope.row.id)" type="text">查看</el-button>
            <el-divider direction="vertical"></el-divider>
            <template v-if="filterForm.tab === 'deleted'">
              <el-button @click="handleReduct(scope.row.id)" type="text">还原</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button @click="handleDel(scope.row.id, 1)" type="text">删除</el-button>
            </template>
            <el-button @click="unDelBlack(scope.row.id)" type="text" v-if="filterForm.tab === 'report'">移出
            </el-button>
            <el-dropdown style="height: 40px; line-height: 40px;"
              v-if="filterForm.tab !== 'deleted' && filterForm.tab !== 'report'"
              @command="handleDropdownSelect($event, scope.$index)">
              <span style="color: #3576FF;" class="el-dropdown-link">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="check"
                  v-if="scope.row.check_status === 1 || scope.row.check_status === 0">通过</el-dropdown-item>
                <el-dropdown-item command="nocheck"
                  v-if="scope.row.check_status === 2 || scope.row.check_status === 0">不通过</el-dropdown-item>
                <el-dropdown-item  v-if="scope.row.check_status === 2"  command="reply">回复</el-dropdown-item>
                <el-dropdown-item command="block">拉黑</el-dropdown-item>
                <el-dropdown-item command="delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible="isReason" title="拉黑原因" width="504px" @close="isReason = false"
      class="dialog-vertical">
      <el-input :rows="6" type="textarea" v-model="reason" placeholder="请输入原因"></el-input>
      <template #footer>
        <el-button @click="isReason = false" size="medium">取消</el-button>
        <el-button @click="blockComment(operate_id)" size="medium" type="primary">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog :visible="isReply" title="回复评论" width="504px" @close="isReply = false" class="dialog-vertical">
      <el-input :rows="6" type="textarea" v-model="reply_content" placeholder="请输入回复内容">
      </el-input>
      <template #footer>
        <el-button @click="isReply = false" size="medium">取消</el-button>
        <el-button @click="sendReply" size="medium" type="primary">确定</el-button>
      </template>
    </el-dialog>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage"></Pagination>

    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog v-model="showReportRecodeDialog" :requestData="ReportRecodeRequest"/>
    <check-dialog ref="checkDialog" @confirm="handleUncheck" />
  </div>
</template>

<script>
import {
  indexList,
  getReply,
  getIsCheck,
  getIsBlack,
  getDelBlack,
  Delete,
  softDelete,
  getPutBack,
  getUpdateVirtual,
} from "../api/circle-comment";
import SourceScreen from "../components/CircleComment/SourceScreen";
import Pagination from "@/base/components/Default/Pagination";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
import CheckDialog from "@/modules/resource-circle/components/CircleComment/CheckDialog";
import ListStatusText from "@/base/components/List/ListStatusText";

export default {
  components: {
    ListStatusText,
    CheckDialog,
    SourceScreen,
    Pagination,
    PopoverInputEdit,
    ReportRecodeDialog,
  },
  data() {
    return {
      loading: true,
      isReason: false,
      isReply: false,
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //用户名
        // order_by: '', //排序
        is_desc: 1, // 排序
        tab: "checking", //当前筛选tab
        page_size: 15, //每页多少条数据
        check_status: -1,
      },
      filters: {
        normal: null,
        blacklist: null,
        report: null,
        deleted: null,
      },
      pageData: {},
      list: [],
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "黑名单", name: "blacklist" },
        { label: "被举报", name: "report" },
        { label: "回收站", name: "deleted" },
      ],
      thead: [
        { label: "所属内容", prop: "target_text", minWidth: 150 },
        { label: "评论者ID", prop: "user_id", minWidth: 100 },
        { label: "评论内容", prop: "content", minWidth: 150 },
        { label: "评论者", prop: "nickname", minWidth: 110 },
        { label: "回复", prop: "replies_count", minWidth: 150, sortable: true },
        {
          label: "用户点赞",
          prop: "likes_count",
          minWidth: 110,
          sortable: true,
        },
        { label: "运营点赞", prop: "praise_count", minWidth: 110 },
        { label: "举报数", prop: "complaints_count", min_width: 100 },
        {
          label: "评论时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        { label: "状态", prop: "check_text", minWidth: 100 },
      ],
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      selectArr: [],
      current_index: -1,
      virtual_like: "",
      operate_id: "",
      reply_content: "",
      reason: "",
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "comment", // 举报数据类型
        data_table: "cyc_circle_comment", // 举报数据表
        data_id: "", // 举报内容id
      },
    };
  },
  watch: {
    isReason(val) {
      if (!val) {
        this.reason = "";
      }
    },
  },
  created() {
    const requestData = this.$route.query?.tab
      ? { ...this.filterForm, ...this.$route.query }
      : this.filterForm;
    this.filterForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.filterForm.tab;
    this.getIndexList(requestData);
  },
  methods: {
    openUncheck() {
      if (!this.selectArr.length) {
        return this.$message.info("请选择数据项");
      }
      this.$refs['checkDialog'].open()
    },
    handleUncheck(e) {
      this.handlePass(e.id || -1, 1, e.content);
    },
    // 查看举报记录
    checkReportRecode({ id }) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    EditRow(e) {
      this.$router.push({
        name: "CircleDetail",
        params: { id: e },
      });
    },
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      const pageData = { ...this.filterForm, page: data.page };
      this.getIndexList(pageData);
    },
    // 列表“更多”选项
    handleDropdownSelect(e, i) {
      let single = this.list[i];
      switch (e) {
        case "reply":
          this.operate_id = single.id;
          this.reply_content = "";
          this.isReply = true;
          break;
        case "nocheck":
          // this.handlePass(single.id, 1);
          this.$refs['checkDialog'].open({id: single.id})
          break;
        case "check":
          this.handlePass(single.id, 2);
          break;
        case "delete":
          this.handleDel(single.id);
          break;
        case "block":
          this.operate_id = single.id;
          this.blockComment(single.id);
      }
    },
    sendReply() {
      getReply({ id: this.operate_id, content: this.reply_content })
        .then((res) => {
          this.$message.success(res.msg);
          this.isReply = false;
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {});
    },
    handlePass(e, i, txt = '') {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        let title = i == 2 ? "是否通过选中评论？" : "是否不通过选中评论";
        console.log(title);
        this.$msgbox
          .confirm(
            i == 2 ? "是否通过选中评论？" : "是否不通过选中评论",
            "提示",
            {
              type: "info",
            }
          )
          .then((res) => {
            let k = {
              id: this.selectArr,
              check_status: i,
              reason: txt
            };
            this.pass(k);
          })
          .catch((err) => {});
      }
      if (e != -1) {
        this.$msgbox
          .confirm(i == 2 ? "是否通过该评论？" : "是否不通过该评论？", "提示", {
            type: "info",
          })
          .then((res) => {
            let k = {
              id: e,
              check_status: i,
              reason: txt
            };
            this.pass(k);
          })
          .catch((err) => {});
      }
    },
    pass(k) {
      this.loading = true;
      getIsCheck(k)
        .then((res) => {
          this.$message.success(res.msg);
          this.$refs['checkDialog'].close()
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 删除
    handleDel(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        if (this.filterForm.tab == "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      }
      if (e != -1) {
        if (this.filterForm.tab == "normal") {
          this.$msgbox
            .confirm("确定要将此数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: e });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将此数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: e });
            })
            .catch((err) => {});
        }
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.getIndexList({
              ...this.filterForm,
              page: this.pageData.current_page,
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.getIndexList({
              ...this.filterForm,
              page: this.pageData.current_page,
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      }
      if (e != -1) {
        this.$msgbox
          .confirm("确定要将此数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: e });
          })
          .catch((err) => {});
      }
    },
    //移除
    unDelBlack(e) {
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        this.$msgbox
          .confirm("确定要将选中数据移除黑名单吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.undataDelBlack({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据移除黑名单吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.undataDelBlack({ id: e });
          })
          .catch((err) => {});
      }
    },
    undataDelBlack(id) {
      this.loading = true;
      getDelBlack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //拉黑
    blockComment(e) {
      console.log(e);
      if (e === -1 && !this.selectArr.length) {
        return this.$message.info("请选择数据项");
      }
      if (!this.reason) {
        this.reason = "";
        this.isReason = true;
        return;
      }
      if (!this.selectArr.length && e == -1) {
        return this.$message.info("请选择数据项");
      }
      if (this.selectArr.length && e == -1) {
        // this.$msgbox
        //     .confirm("确定要将选中数据拉入黑名单吗？", "提示", {
        //         type: "info",
        //     })
        //     .then((res) => {
        //
        //     })
        //     .catch((err) => {});
        var i = {
          id: this.selectArr,
          reason: this.reason,
        };
        this.updataIsBlack(i);
      } else {
        // this.$msgbox
        //     .confirm("确定要将此数据拉入黑名单吗？", "提示", {
        //         type: "info",
        //     })
        //     .then((res) => {
        //
        //     })
        //     .catch((err) => {});
        var i = {
          id: e,
          reason: this.reason,
        };
        this.updataIsBlack(i);
      }
    },
    updataIsBlack(i) {
      getIsBlack(i)
        .then((res) => {
          this.isReason = false;
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {});
    },

    // 还原数据
    Reduct(id) {
      this.loading = true;
      getPutBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getIndexList({
            ...this.filterForm,
            page: this.pageData.current_page,
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    updateIsShow(e, id, i) {
      this.loading = true;
      getUpdateVirtual({ id: id, praise_count: Number(e) })
        .then((res) => {
          this.$message({
            type: "success",
            message: res.msg,
          });
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    showInput(i) {
      this.current_index = i;
      this.virtual_like = this.list[i].praise_count;
    },
    hideInput() {
      this.current_index = -1;
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection);
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },

    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getIndexList(this.filterForm);
    },
    getIndexList(requestData) {
      // this.list = [];
      this.pageData = {};
      this.loading = true;
      indexList(requestData)
        .then((res) => {
          const { data } = res;
          this.list = data.data;
          this.pageData = data;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {});
    },
    // 筛选回调
    ok(e) {
      // const pageData = {};
      this.filterForm = {...e, page: 1};
      this.getIndexList(this.filterForm);
    },
    //tab栏切换
    handleChangeTab(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //用户名
          // order_by: '', //排序
          is_desc: "", // 排序
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页多少条数据
          check_status: -1,
        };
      }
      this.getIndexList(this.filterForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-content {
  ::v-deep.el-textarea__inner {
    height: 100%;
  }
  /* 列表状态小圆点 */
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .yellow-dot {
    background: rgba(255, 203, 44, 1);
  }

  .green-dot {
    background: rgba(19, 206, 102, 1);
  }

  .gray-dot {
    background: rgba(144, 147, 153, 1);
  }
}
</style>
