import { render, staticRenderFns } from "./CheckDialog.vue?vue&type=template&id=35e477de&scoped=true&"
import script from "./CheckDialog.vue?vue&type=script&lang=js&"
export * from "./CheckDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e477de",
  null
  
)

export default component.exports