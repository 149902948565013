<template>
  <el-dialog title="不通过审核" :visible="visible" width="500px" class="dialog-vertical"
             @close="close()">
    <el-form size="medium" class="medium-form" :model="form" :rules="rules"
             label-width="65px"
             ref="reasonContent" @submit.native.prevent="()=>{}">
      <el-form-item prop="content" style="margin-bottom: 0" label="原因：">
        <el-input type="textarea" :maxlength="20" show-word-limit v-focus
                  v-model="form.content" placeholder="请输入内容" style="min-height: 120px;" />
        <p class="info"><i class="el-icon-info" />将通过订阅消息、系统消息发送通知</p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button type="primary" :loading="loading" size="small" @click="submit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "CheckDialog",
    data() {
      return {
        visible: false,
        rules: {
          content: [{ required: true, message: "请输入不通过原因", trigger: "blur" }],
        },
        loading: false,
        form: {
          content: "",
          id: 0
        }
      }
    },
    methods: {
      open({id} = {id: 0}) {
        this.form.id = id
        this.visible = true
      },
      close() {
        this.visible = false
        this.$refs['reasonContent'].clearValidate()
        this.loading = false
        this.form.content = ''
      },
      submit() {
        this.$refs["reasonContent"].validate(valid => {
          if (valid) {
            // this.loading = true
            this.$emit('confirm', this.form)
          }
        })
      }
    },
  }
</script>

<style scoped>

</style>
